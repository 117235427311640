import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Container, CardBody, Col, Form, Row, Label } from 'reactstrap'
import GoogleFontLoader from 'react-google-font-loader'
import '../Button.css'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import { myStoryOperations } from '../../state/ducks/story'
import { Table } from 'react-bootstrap'
import AddAdditionalOrderBook from './AddAdditionalOrderBook'
import * as API_URL from '../../env.js'
import AddOrderBook from './AddOrderBook.jsx'

class OrderBooks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showInputField: false,
      modalBookOpen: false,
      modalAdditionalBookOpen: false,
      modalAdditionalBookOpenEdit: false,
      modalOrderBookOpenEdit: false,
      selected: 'colour',
      selectedOption: '',
      selectedStoryData: {},
      print_color_id: null, // initialize the state variable
      colorPrice: 0,
      copies: 1,
      shippingPrice: 0,
      subtotal: 0,
      additionalBooks: [],
      total: '',
      includeTotal: '',
      orderBooks: [],
      selectedAdditionalOrderData: {},
      selectedOrderBookData: {},
      isDeleteConfirm: false,
      isCancelConfirm: false,
      showPayment: false,
      discountsData: [],
      errors: '',
      subtotalAmount: '',
    }
  }

  async componentDidMount() {
    const { loggedInUserId } = this.props
    if (!loggedInUserId) {
      console.log('No user ID found')
      return
    }
    const storyId = localStorage.getItem('selectedStoryId')
    const response = await this.props.getMyStory({ id: storyId })
    this.setState({ selectedStoryData: response.payload })
    await this.fetchAdditionalBooks()
    await this.fetchOrderBooks()
  }

  async componentDidUpdate(prevProps, prevState) {
    // Recalculate subtotal if prices or options change
    if (
      prevState.colorPrice !== this.state.colorPrice ||
      prevState.shippingPrice !== this.state.shippingPrice
    ) {
      this.calculateSubtotal()
    }

    // Fetch additional books only if the flag is true
    if (this.state.shouldFetchAdditionalBooks) {
      await this.fetchAdditionalBooks()
    }
    if (this.state.shouldFetchOrderBooks) {
      await this.fetchOrderBooks()
    }
  }

  fetchAdditionalBooks = async () => {
    try {
      const response = await this.props.listAdditionalOrderBook()
      this.setState({
        id: response.payload.id,
        additionalBooks: response.payload.orderBooksWithShipping,
        total: Number(response.payload.totalSubtotal) || 0,
        shouldFetchAdditionalBooks: false, // Reset the flag after fetching
      })
    } catch (error) {
      this.setState({
        error: error.message,
        shouldFetchAdditionalBooks: false, // Reset the flag if there's an error
      })
    }
  }

  fetchOrderBooks = async () => {
    try {
      const response = await this.props.listOrderBook()
      this.setState({
        id: response.payload.id,
        includeTotal: Number(response.payload.totalSubtotal) || 0,
        orderBooks: response.payload.orderBooksWithShipping,
        shouldFetchOrderBooks: false, // Reset the flag after fetching
      })
    } catch (error) {
      this.setState({
        error: error.message,
        shouldFetchOrderBooks: false, // Reset the flag if there's an error
      })
    }
  }

  calculateSubtotal = () => {
    const subtotal = this.state.colorPrice + this.state.shippingPrice
    this.setState({ subtotal })
  }

  onInsertAdditional = async (event) => {
    event.preventDefault()
    try {
      const payload = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        copies: this.state.copies,
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        postcode: this.state.postcode,
        shipping_id: this.state.selectedOption,
        print_color_id: this.state.print_color_id,
        subtotal: this.state.subtotal,
      }
      const response = await this.props.addAdditionalOrderBook(payload)
      toast.success(response.message, { autoClose: 10000 })
      window.open('/my-stories/order-books/', '_self')
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 })
      console.log(err)
    }
  }

  onCancelConfirm = (e) => {
    if (this.state.isCancelConfirm) {
      this.handleShowAdditionalBookEdit(e)
    } else {
      this.setState({
        isCancelConfirm: true,
      })
      setTimeout(() => {
        this.setState({
          isCancelConfirm: false,
        })
      }, 3000)
    }
  }

  onDeleteConfirm = (e) => {
    if (this.state.isDeleteConfirm) {
      this.onDeleteClick(e)
    } else {
      this.setState({
        isDeleteConfirm: true,
      })
      setTimeout(() => {
        this.setState({
          isDeleteConfirm: false,
        })
      }, 3000)
    }
  }

  onDeleteClick = async (e) => {
    e.preventDefault()
    try {
      let additionalOrderId = e.target.getAttribute('id')
      const response = await this.props.deleteAdditionalOrderBook({
        id: additionalOrderId,
      })
      toast.success(response.message, { autoClose: 10000 })
      window.open('/my-stories/order-books/', '_self')
    } catch (err) {
      console.log(err)
    }
  }

  combinedClickHandler = async (e) => {
    e.preventDefault()
    const additionalOrderId = e.target.getAttribute('data-test-id')
    try {
      // Fetch the data for the specific item from the server
      const response = await this.props.fetchAdditionalOrderBookById({
        orderBookId: additionalOrderId,
      })
      const additionalOrderData = response.payload
      // Map shipping_name to the correct radio button value, if necessary
      const shippingOptionMapping = {
        'UK and Europe': '1',
        USA: '2',
        Canada: '3',
        Israel: '4',
        Dubai: '5',
      }

      const selectedOption =
        shippingOptionMapping[additionalOrderData.shipping_name] || ''

      // Set the fetched data to the component state
      this.setState({
        selectedAdditionalOrderData: additionalOrderData,
        id: additionalOrderData.id,
        copies: additionalOrderData.copies,
        first_name: additionalOrderData.first_name,
        last_name: additionalOrderData.last_name,
        address: additionalOrderData.address,
        address2: additionalOrderData.address2,
        city: additionalOrderData.city,
        state: additionalOrderData.state,
        postcode: additionalOrderData.postcode,
        selectedOption: selectedOption,
        print_color_id: additionalOrderData.print_color_id,
        subtotal: additionalOrderData.subtotal,
        modalAdditionalBookOpenEdit: true,
      })
    } catch (err) {
      console.log(err)
    }
  }

  handleGoToPayment = async (event) => {
    event.preventDefault()

    try {
      const {
        total,
        includeTotal,
        discountAmount,
        discountedTotal,
        additionalBooks,
        orderBooks,
      } = this.state

      // Calculate final amount considering discount
      const finalAmount =
        discountAmount > 0
          ? Number(discountedTotal)
          : Number(total) + Number(includeTotal)

      // Get order IDs
      const orderBookIds = orderBooks.map((data) => 'O' + data.id)
      const additionalOrderIds = additionalBooks.map((data) => 'A' + data.id)
      const orderIds = [...orderBookIds, ...additionalOrderIds].join(';')

      // Get user IDs
      const userIds = [
        ...orderBooks.map((data) => data.user_id),
        ...additionalBooks.map((data) => data.user_id),
      ].join(';')

      // Make payment request
      let paymentResponse = await this.props.Payment({
        amount: finalAmount,
        orderId: orderIds,
        userId: userIds,
        payment_method_id: 'pm_card_visa',
        discount_amount: discountAmount > 0 ? discountAmount : 0, // Include discount info
        original_amount: Number(total) + Number(includeTotal), // Include original amount
      })

      const url = paymentResponse.payload
      if (url) {
        window.location.href = url // Redirects to the payment page
      } else {
        toast.error('No payment URL provided.', { autoClose: 10000 })
      }
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'An unexpected error occurred',
        { autoClose: 10000 }
      )
      console.error('Payment error:', err)
    }

    this.setState({ showPayment: true })
  }

  handleInput = (e) => {
    const { name, value } = e.target
    let updatedValue = value
    if (value > 0) {
      updatedValue = value
    } else {
      updatedValue = ''
    }
    const { colorPrice, shippingPrice } = this.state

    this.setState({
      selectedAdditionalOrderData: {
        ...this.state.selectedAdditionalOrderData,
        [name]: updatedValue,
      },
      subtotal: value * (colorPrice + shippingPrice),
    })
  }
  onInsert = async (event) => {
    event.preventDefault()
    try {
      const payload = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        postcode: this.state.postcode,
        shipping_id: this.state.selectedOption,
        print_color_id: this.state.print_color_id,
        subtotal: this.state.subtotal,
      }
      const response = await this.props.addOrderBook(payload)
      toast.success(response.message, { autoClose: 10000 })
      window.open('/my-stories/order-books/', '_self')
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 })
      console.log(err)
    }
  }

  onOrderBookDeleteClick = async (e) => {
    e.preventDefault()
    try {
      let OrderId = e.target.getAttribute('data-test-id')
      const response = await this.props.deleteOrderBook({ id: OrderId })
      toast.success(response.message, { autoClose: 10000 })
      window.open('/my-stories/order-books/', '_self')
    } catch (err) {
      console.log(err)
    }
  }

  onCancelClick = () => {
    window.open('/my-stories/order-books/', '_self')
  }

  handleInputOrderBook = async (e) => {
    try {
      const { id, value } = e.target
      this.setState((prevState) => ({
        selectedOrderBookData: {
          ...prevState.selectedOrderBookData,
          [id]: value,
        },
      }))
    } catch (err) {
      console.log(err)
    }
  }

  onUpdateOrderBook = async (e) => {
    e.preventDefault()
    try {
      const { selectedOrderBookData } = this.state
      let updateResponse = await this.props.updateOrderBook({
        id: selectedOrderBookData.id,
        first_name: selectedOrderBookData.first_name,
        last_name: selectedOrderBookData.last_name,
        address: selectedOrderBookData.address,
        address2: selectedOrderBookData.address2,
        city: selectedOrderBookData.city,
        state: selectedOrderBookData.state,
        postcode: selectedOrderBookData.postcode,
        selectedOption: selectedOrderBookData.shipping_Name,
        print_color_id: selectedOrderBookData.print_color_id,
        subtotal: selectedOrderBookData.subtotal,
      })
      window.open('/my-stories/order-books/', '_self')
      toast.success(updateResponse.message, { autoClose: 10000 })
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 })
      console.log(err)
    }
  }

  combinedClickHandlerOrderBook = async (e) => {
    e.preventDefault()
    const OrderId = e.target.getAttribute('data-test-id')
    try {
      // Fetch the data for the specific item from the server
      const response = await this.props.fetchOrderBookById({
        orderBookId: OrderId,
      })
      const OrderBookData = response.payload
      // Set the fetched data to the component state
      this.setState({
        selectedOrderBookData: OrderBookData,
        first_name: OrderBookData.first_name,
        last_name: OrderBookData.last_name,
        address: OrderBookData.address,
        address2: OrderBookData.address2,
        city: OrderBookData.city,
        state: OrderBookData.state,
        postcode: OrderBookData.postcode,
        selectedOption: OrderBookData.shipping_name,
        print_color_id: OrderBookData.print_color_id,
        subtotal: OrderBookData.subtotal,
        modalOrderBookOpenEdit: true,
      })
    } catch (err) {
      console.log(err)
    }
  }

  onUpdate = async (e) => {
    e.preventDefault()
    try {
      const { selectedAdditionalOrderData } = this.state
      let updateResponse = await this.props.updateAdditionalOrderBook({
        id: selectedAdditionalOrderData.id,
        copies: selectedAdditionalOrderData.copies,
        first_name: selectedAdditionalOrderData.first_name,
        last_name: selectedAdditionalOrderData.last_name,
        address: selectedAdditionalOrderData.address,
        address2: selectedAdditionalOrderData.address2,
        city: selectedAdditionalOrderData.city,
        state: selectedAdditionalOrderData.state,
        postcode: selectedAdditionalOrderData.postcode,
        shipping_Name: selectedAdditionalOrderData.selectedOption,
        print_color_id: selectedAdditionalOrderData.print_color_id,
        subtotal: selectedAdditionalOrderData.subtotal,
      })
      window.open('/my-stories/order-books/', '_self')
      toast.success(updateResponse.message, { autoClose: 10000 })
    } catch (err) {
      toast.error(err.updateResponse?.data?.message, { autoClose: 10000 })
      console.log(err)
    }
  }

  handleCheckboxChange = (e) => {
    this.setState({ showInputField: e.target.checked })
  }

  handleShowBook = () => {
    this.setState((prevState) => ({
      modalBookOpen: !prevState.modalBookOpen,
    }))
  }

  handleShowAdditionalBook = () => {
    this.setState((prevState) => ({
      modalAdditionalBookOpen: !prevState.modalAdditionalBookOpen,
    }))
  }

  handleShowAdditionalBookEdit = (e) => {
    this.setState((prevState) => ({
      modalAdditionalBookOpenEdit: !prevState.modalAdditionalBookOpenEdit,
    }))
    if (this.state.modalAdditionalBookOpenEdit) {
      this.fetchAdditionalBooks(e)
    }
  }

  handleShowOrderBookEdit = (e) => {
    this.setState((prevState) => ({
      modalOrderBookOpenEdit: !prevState.modalOrderBookOpenEdit,
    }))
    if (this.state.modalOrderBookOpenEdit) {
      this.fetchOrderBooks(e)
    }
  }

  handleSelect = (option) => {
    let print_color_id = null
    if (option === 'colour') {
      print_color_id = '2' // ID for Colour inner pages
    } else if (option === 'bw') {
      print_color_id = '1' // ID for Black and white inner pages
    }

    this.setState({
      selected: option,
      print_color_id: print_color_id, // update print_color_id based on selection
    })
  }

  handleColorSelect = (option) => {
    let colorPrice = 0
    if (option === 2) {
      colorPrice = 50
    } else if (option === 1) {
      colorPrice = 30
    }
    const { copies, shippingPrice } = this.state
    this.setState({
      print_color_id: option, // update print_color_id based on selection
      colorPrice: colorPrice, // update color price based on selection
      subtotal: copies * (colorPrice + shippingPrice),
    })
  }

  updateSubtotal = () => {
    const { copies, colorPrice, shippingPrice } = this.state
    const subtotal = copies * (colorPrice + shippingPrice)
    this.setState({ subtotal })
  }

  handleOptionChange = (event) => {
    const shippingId = event.target.value
    let shippingPrice = 0
    switch (shippingId) {
      case '1':
        shippingPrice = 7
        break
      case '2':
        shippingPrice = 7
        break
      case '3':
        shippingPrice = 9
        break
      case '4':
        shippingPrice = 12
        break
      case '5':
        shippingPrice = 6
        break
      default:
        shippingPrice = 0
    }
    const { copies, colorPrice } = this.state

    this.setState({
      selectedOption: shippingId,
      shippingPrice: shippingPrice,
      subtotal: copies * (colorPrice + shippingPrice),
    })
  }

  handleDiscountChange = (event) => {
    this.setState({
      discountCode: event.target.value,
      errors: '', // Clear errors when user starts typing again
    })
  }
  applyDiscount = async () => {
    const { discountCode, total, includeTotal } = this.state
    const originalTotal = total + includeTotal
    if (!discountCode) {
      toast.error('Please enter a discount code')
      return
    }
    try {
      const response = await this.props.getDiscountCode({ code: discountCode })

      // Check if the response has valid discount data
      if (
        response.status === 200 &&
        response.payload &&
        response.payload.value
      ) {
        const discountValue = parseFloat(response.payload.value || 0)
        const discountType = response.payload.type

        let discountAmount = 0
        if (discountType === 'percentage') {
          discountAmount = (originalTotal * discountValue) / 100
        } else {
          discountAmount = discountValue
        }

        discountAmount = Math.min(discountAmount, originalTotal)
        const discountedTotal = originalTotal - discountAmount

        this.setState({
          discountAmount,
          discountedTotal,
          originalTotal,
          errors: '',
        })

        toast.success('Discount code applied successfully!')
      } else {
        // Handle invalid discount code
        this.setState({
          errors: 'The discount code you entered is not valid.',
          discountAmount: 0,
          discountedTotal: originalTotal,
          discountCode: '', // Clear the invalid code
        })
      }
    } catch (error) {
      // Handle API errors
      this.setState({
        errors: 'The discount code you entered is not valid.',
        discountAmount: 0,
        discountedTotal: originalTotal,
        discountCode: '', // Clear the invalid code
      })
    }
  }

  render() {
    const {
      showInputField,
      modalBookOpen,
      modalAdditionalBookOpen,
      additionalBooks,
      orderBooks,
      modalAdditionalBookOpenEdit,
      selectedStoryData,
    } = this.state

    // Convert values to numbers and handle potential NaN
    const total = Number(this.state.total) || 0
    const includeTotal = Number(this.state.includeTotal) || 0
    const subtotal = total + includeTotal

    const isLocal = window.location.hostname === 'localhost'
    const apiUrl = API_URL.API_URL
    const coverPageUrl =
      selectedStoryData && selectedStoryData.cover_page
        ? selectedStoryData.cover_page.split(isLocal ? '\\' : '/').pop()
        : ''
    const imageUrl = coverPageUrl
      ? `${apiUrl}/images/${coverPageUrl}` // Use local or live URL
      : ''
    const theadStyle = {
      background: '#f2f2f2',
      border: 'none',
      fontSize: '13px',
      fontWeight: '700',
      fontFamily: 'Fira Sans',
    }
    const tdStyle = {
      background: '#ffffff',
      border: 'none',
      fontSize: '13px',
      fontWeight: '300',
      fontFamily: 'Fira Sans',
    }

    return (
      <Fragment className="">
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400, 600],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: 'center',
                    font: 'normal normal 300 2.2rem/1.4 Martel',
                    color: '#000',
                  }}
                >
                  Order Books
                </h2>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #FEC1EB',
                      height: '1px',
                      width: '102px',
                      display: 'inline-block',
                    }}
                  ></span>
                </div>
              </Row>
              <Row
                className="mt-3"
                style={{ background: '#EEE', padding: '3%' }}
              >
                <Col md="1"></Col>
                <Col md="2">
                  <div
                    className=""
                    style={{
                      height: '232px',
                      width: '160px',
                      boxShadow: '-4px 5px 12px 0px #bababa',
                      borderRadius: '2px',
                      margin: '0 auto 15px auto',
                    }}
                  >
                    {selectedStoryData && selectedStoryData.cover_page ? (
                      <img
                        src={imageUrl}
                        alt="Cover Page"
                        style={{
                          width: '200px',
                          height: '240px',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <>
                        <div style={{ padding: '20% 10% 20% 13%' }}>
                          <img
                            src={selectedStoryData.image}
                            width="120"
                            height="120"
                            alt="story"
                          />
                        </div>
                        <div style={{ fontSize: '.6rem', textAlign: 'center' }}>
                          {selectedStoryData.title}
                        </div>
                      </>
                    )}
                  </div>
                </Col>
                <Col md="8">
                  <div
                    style={{
                      fontSize: '0.95rem',
                      fontWeight: '700',
                      fontFamily: 'Fira Sans',
                    }}
                  >
                    Included prints
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      clear: 'both',
                      fontSize: '15px',
                      fontWeight: '300',
                      fontFamily: 'Fira Sans',
                      color: '#404040',
                      lineHeight: 'inherit',
                    }}
                  >
                    1 book(s) included in your subscription. It will be printed
                    with a black and white interior but the book cover will
                    always be printed in colour. You can upgrade your book to a
                    colour interior for an additional cost.
                  </div>

                  {orderBooks.length > 0 ? (
                    <Table
                      ref="tbl"
                      striped
                      hover
                      responsive
                      bordered
                      id="data-table-zero"
                    >
                      <thead>
                        <tr style={{ border: '1px solid #ccc' }}>
                          <th style={theadStyle}>Delivery address</th>
                          <th style={theadStyle}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderBooks.map((data, i) => (
                          <tr key={i}>
                            <td style={tdStyle}>
                              {data.address}
                              <br />
                              {data.address2}
                              <br />
                              {data.city},&nbsp;
                              {data.state}&nbsp;
                              {data.postcode}
                            </td>

                            <td
                              style={{
                                background: '#ffffff',
                                border: 'none',
                                fontSize: '13px',
                                fontWeight: '400',
                                fontFamily: 'Fira Sans',
                              }}
                            >
                              <a
                                herf="#"
                                type="button"
                                data-test-id={data.id}
                                onClick={this.combinedClickHandlerOrderBook}
                                style={{ textDecoration: 'underline' }}
                              >
                                Edit
                              </a>
                              &nbsp;
                              <a
                                herf="#"
                                type="button"
                                data-test-id={data.id}
                                onClick={this.onOrderBookDeleteClick}
                                style={{
                                  color: '#c00',
                                  textDecoration: 'underline',
                                }}
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      <div
                        className="mt-3"
                        style={{
                          background: '#f2f2f2',
                          border: '1px solid #ccc',
                          fontSize: '13px',
                          fontWeight: '700',
                          padding: '15px',
                          fontFamily: 'Fira Sans',
                        }}
                      >
                        Delivery Address
                      </div>
                      <div
                        style={{
                          background: '#fff',
                          border: '1px solid #ccc',
                          padding: '15px',
                          fontSize: '14px',
                          textAlign: 'center',
                          fontFamily: 'Fira Sans',
                          fontWeight: '300',
                        }}
                      >
                        There are no books.
                      </div>
                    </>
                  )}

                  <AddOrderBook
                    isEdit={true}
                    isvisible={this.state.modalOrderBookOpenEdit}
                    toggle={(e) => this.handleShowOrderBookEdit(e)}
                    addOrderData={this.state.selectedOrderBookData}
                    onDeleteClick={this.onDeleteConfirm}
                  />

                  <div className="mt-2">
                    <button
                      type="button"
                      className="white-button"
                      onClick={this.handleShowBook}
                    >
                      ADD BOOK
                    </button>
                    <AddOrderBook
                      isvisible={modalBookOpen}
                      toggle={() => this.handleShowBook()}
                    />
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      fontSize: '0.95rem',
                      fontWeight: '700',
                      fontFamily: 'Fira Sans',
                    }}
                  >
                    Order extra copies
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      clear: 'both',
                      fontSize: '15px',
                      fontWeight: '300',
                      fontFamily: 'Fira Sans',
                      color: '#404040',
                      lineHeight: 'inherit',
                    }}
                  >
                    Want more copies of this book? Please add additional books
                    below and where you would like them delivered to.
                  </div>

                  {additionalBooks.length > 0 ? (
                    <Table
                      ref="tbl"
                      striped
                      hover
                      responsive
                      bordered
                      id="data-table-zero"
                    >
                      <thead>
                        <tr style={{ border: '1px solid #ccc' }}>
                          <th style={theadStyle}>How many copies?</th>
                          <th style={theadStyle}>Delivery address</th>
                          <th style={theadStyle}>Shipping</th>
                          <th style={theadStyle}>Sub total</th>
                          <th style={theadStyle}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {additionalBooks.map((data, i) => (
                          <tr key={i}>
                            <td style={tdStyle}>{data.copies}</td>
                            <td style={tdStyle}>
                              {data.address}
                              <br />
                              {data.address2}
                              <br />
                              {data.city},&nbsp;
                              {data.state}&nbsp;
                              {data.postcode}
                            </td>
                            <td style={tdStyle}>{data.shipping_price}</td>
                            <td style={tdStyle}>£{data.subtotal}</td>
                            <td
                              style={{
                                background: '#ffffff',
                                border: 'none',
                                fontSize: '13px',
                                fontWeight: '400',
                                fontFamily: 'Fira Sans',
                              }}
                            >
                              <a
                                herf="#"
                                type="button"
                                data-test-id={data.id}
                                onClick={this.combinedClickHandler}
                                style={{ textDecoration: 'underline' }}
                              >
                                Edit
                              </a>
                              &nbsp;
                              <a
                                herf="#"
                                type="button"
                                id={data.id}
                                onClick={this.onDeleteClick}
                                style={{
                                  color: '#c00',
                                  textDecoration: 'underline',
                                }}
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      {' '}
                      <div
                        className="mt-3"
                        style={{
                          background: '#f2f2f2',
                          border: '1px solid #ccc',
                          fontSize: '13px',
                          fontWeight: '700',
                          padding: '15px',
                          fontFamily: 'Fira Sans',
                        }}
                      >
                        <span style={{ marginRight: '12%' }}>
                          How many copies?
                        </span>
                        <span style={{ marginRight: '12%' }}>
                          Delivery address
                        </span>
                        <span style={{ marginRight: '12%' }}>Shipping</span>
                        <span>Sub total</span>
                      </div>
                      <div
                        style={{
                          background: '#fff',
                          border: '1px solid #ccc',
                          padding: '15px',
                          fontSize: '14px',
                          textAlign: 'center',
                          fontFamily: 'Fira Sans',
                          fontWeight: '300',
                        }}
                      >
                        <spna>There are no additional books.</spna>
                      </div>
                    </>
                  )}

                  <AddAdditionalOrderBook
                    isEdit={true}
                    isvisible={modalAdditionalBookOpenEdit}
                    toggle={(e) => this.handleShowAdditionalBookEdit(e)}
                    additionalOrderData={this.state.selectedAdditionalOrderData}
                    onDeleteClick={this.onDeleteConfirm}
                  />

                  <div className="mt-2">
                    <button
                      type="button"
                      className="white-button"
                      onClick={this.handleShowAdditionalBook}
                    >
                      ADD ADDITIONAL BOOK
                    </button>
                  </div>
                  <AddAdditionalOrderBook
                    isvisible={modalAdditionalBookOpen}
                    toggle={() => this.handleShowAdditionalBook()}
                  />

                  <div
                    className="mt-5 pb-3"
                    style={{
                      font: 'normal normal 300 1.4rem/1.4 Martel',
                      color: '#000',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    Payment
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '0.95rem',
                        fontWeight: '700',
                        fontFamily: 'Fira Sans',
                      }}
                    >
                      Included copies
                    </span>
                    <div style={{ textAlign: 'right' }}>
                      <span
                        style={{ fontWeight: '300', fontFamily: 'Fira Sans' }}
                      >
                        Price:{' '}
                      </span>
                      <span
                        style={{
                          color: '#8A1A75',
                          fontWeight: '700',
                          fontFamily: 'Fira Sans',
                        }}
                      >
                        £{this.state.includeTotal}.00
                      </span>
                    </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '0.95rem',
                        fontWeight: '700',
                        fontFamily: 'Fira Sans',
                      }}
                    >
                      Extra copies
                    </span>
                    <div style={{ textAlign: 'right' }}>
                      <span
                        style={{ fontWeight: '300', fontFamily: 'Fira Sans' }}
                      >
                        Price:{' '}
                      </span>
                      <span
                        style={{
                          color: '#8A1A75',
                          fontWeight: '700',
                          fontFamily: 'Fira Sans',
                        }}
                      >
                        £{this.state.total}.00
                      </span>
                    </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      background: '#fff',
                      border: '1px solid #ccc',
                      fontSize: '14px',
                      fontWeight: '700',
                      fontFamily: 'Fira Sans',
                      padding: '10px',
                    }}
                  >
                    <div>Discount codes</div>
                    <input
                      type="text"
                      value={this.state.discountCode}
                      onChange={this.handleDiscountChange}
                      className="mt-2"
                      style={{
                        padding: '8px',
                        border: this.state.errors
                          ? '1px solid #dc3545'
                          : '1px solid #808080',
                      }}
                    />
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="white-button"
                      onClick={this.applyDiscount}
                    >
                      APPLY
                    </button>
                    {this.state.errors && (
                      <div
                        style={{
                          color: '#dc3545',
                          fontSize: '12px',
                          marginTop: '5px',
                          fontWeight: '400',
                        }}
                      >
                        {this.state.errors}
                      </div>
                    )}
                  </div>

                  <div style={{ background: '#fff', padding: '15px' }}>
                    <p
                      style={{
                        margin: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontFamily: 'Fira Sans',
                        fontSize: '14px',
                        color: '#404040',
                        fontWeight: '300',
                      }}
                    >
                      Subtotal
                      <span style={{ color: '#8A1A75' }}>
                        £{subtotal.toFixed(2)}
                      </span>
                    </p>

                    {this.state.discountAmount > 0 && (
                      <p
                        style={{
                          margin: '10px 0',
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontFamily: 'Fira Sans',
                          fontSize: '14px',
                          color: '#404040',
                          fontWeight: '300',
                        }}
                      >
                        Discount
                        <span style={{ color: '#8A1A75' }}>
                          -£{Number(this.state.discountAmount).toFixed(2)}
                        </span>
                      </p>
                    )}

                    <p
                      style={{
                        margin: '10px 0 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontFamily: 'Fira Sans',
                        fontSize: '14px',
                        fontWeight: '700',
                      }}
                    >
                      Total
                      <span style={{ color: '#8A1A75' }}>
                        £
                        {(this.state.discountAmount > 0
                          ? Number(this.state.discountedTotal)
                          : subtotal
                        ).toFixed(2)}
                      </span>
                    </p>
                  </div>
                  <div
                    className="mt-5 pb-3"
                    style={{
                      font: 'normal normal 300 1.4rem/1.4 Martel',
                      color: '#000',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    Disclaimer
                  </div>
                  <div
                    className="mt-3 pb-4"
                    style={{
                      background: '#fff',
                      borderBottom: '1px solid #ccc',
                      padding: '10px 15px',
                      fontSize: '16px',
                      fontFamily: 'Fira Sans',
                      fontWeight: '300',
                    }}
                  >
                    <div>
                      As a reminder, no one on our team reviews your stories.
                      What you see in the preview is what your book will look
                      like when printed.
                    </div>
                    <div className="mt-3">
                      Here are some examples of issues that our automated system
                      might not catch:
                    </div>
                    <div className="mt-3">
                      <ul
                        style={{ listStyleType: 'disc', paddingLeft: '20px' }}
                      >
                        <li>Typos, misspellings and grammar mistakes</li>
                        <li>Missing images and low quality images</li>
                        <li>
                          Replies that you do not want to include in the book
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="pb-4"
                    style={{
                      background: '#fff',
                      padding: '10px 15px',
                      fontFamily: 'Fira Sans',
                      fontWeight: '300',
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: '0.95rem',
                          fontWeight: '700',
                          fontFamily: 'Fira Sans',
                        }}
                      >
                        I'm OK with this
                      </span>
                      &nbsp;
                      <span
                        style={{
                          fontStyle: 'italic',
                          fontWeight: '400',
                          fontSize: '12px',
                          color: '#c02b0a',
                        }}
                      >
                        (Required)
                      </span>
                    </div>
                    <div className="mt-2">
                      <Label style={{ fontSize: '15px' }}>
                        <input
                          type="checkbox"
                          onChange={this.handleCheckboxChange}
                        />
                        &nbsp; Please print my book 'as is'
                      </Label>
                    </div>
                  </div>
                  {showInputField === false ? (
                    <></>
                  ) : (
                    <div className="mt-3">
                      <button
                        type="submit"
                        style={{
                          background: '#8A1A75',
                          letterSpacing: '0.15rem',
                          border: '2px solid #8A1A75',
                          fontWeight: '300',
                          color: '#fff',
                          borderRadius: '30px',
                          padding: '0 20px',
                          textTransform: 'uppercase',
                          minHeight: '45px',
                        }}
                        onClick={this.handleGoToPayment}
                      >
                        Go to Payment
                      </button>
                      {/* {showPayment && <StripeProvider  />} */}
                    </div>
                  )}
                </Col>
                <Col md="1"></Col>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUserName: state.auth.session.user.user_name,
    loggedInUserId: state.auth.session.user.id,
  }
}

const mapDispatchToProps = {
  getMyStory: myStoryOperations.getMyStory,
  addOrderBook: myStoryOperations.addOrderBook,
  listOrderBook: myStoryOperations.listOrderBook,
  deleteOrderBook: myStoryOperations.deleteOrderBook,
  fetchOrderBookById: myStoryOperations.fetchOrderBookById,
  updateOrderBook: myStoryOperations.updateOrderBook,
  addAdditionalOrderBook: myStoryOperations.addAdditionalOrderBook,
  listAdditionalOrderBook: myStoryOperations.listAdditionalOrderBook,
  deleteAdditionalOrderBook: myStoryOperations.deleteAdditionalOrderBook,
  fetchAdditionalOrderBookById: myStoryOperations.fetchAdditionalOrderBookById,
  updateAdditionalOrderBook: myStoryOperations.updateAdditionalOrderBook,
  Payment: myStoryOperations.Payment,
  getDiscountCode: myStoryOperations.getDiscountCode,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderBooks)
