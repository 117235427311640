import React, { Fragment } from 'react'
import { Container, CardBody, Col, Form, Row } from 'reactstrap'
import { myStoryOperations } from '../../state/ducks/story'
import { connect } from 'react-redux'
import GoogleFontLoader from 'react-google-font-loader'
import { Link } from 'react-router-dom'
import '../Button.css'
import * as API_URL from '../../env.js'
class MyStories extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      myStorieData: [],
    }
  }

  async componentDidMount() {
    const { loggedInUserId } = this.props
    if (!loggedInUserId) {
      console.log('No user ID found')
      return
    }

    const myStories = await this.props.listMyStoryDetails({
      id: loggedInUserId,
    })

    this.setState({
      myStorieData: myStories.payload,
    })
  }

  handleClick = (e) => {
    const storyId = e.currentTarget.getAttribute('data-test-id')
    localStorage.setItem('selectedStoryId', storyId)
    window.open('/my-stories/edit-my-stories/', '_self')
  }

  handleRenewClick = (e) => {
    const storyId = e.currentTarget.getAttribute('data-test-id')
    localStorage.setItem('selectedStoryId', storyId)
    window.open('/renewal-subscription/', '_self')
  }

  handlePrintClick = (e) => {
    const storyId = e.currentTarget.getAttribute('data-test-id')
    localStorage.setItem('selectedStoryId', storyId)
    window.open('/my-stories/order-books/', '_self')
  }

  handleAddQuestionClick = (e) => {
    const storyId = e.currentTarget.getAttribute('data-test-id')
    localStorage.setItem('selectedStoryId', storyId)
    window.open('/my-stories/add-question/', '_self')
  }

  handleViewQuestionClick = (e) => {
    const storyId = e.currentTarget.getAttribute('data-test-id')
    localStorage.setItem('selectedStoryId', storyId)
    window.open('/my-stories/view-question/', '_self')
  }

  render() {
    const { myStorieData } = this.state
    const apiUrl = API_URL.API_URL
    const liStyle = {
      fontWeight: '500',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
    }
    const ulStyle = {
      display: 'flex',
      listStyle: 'none',
      padding: 0,
      justifyContent: 'center',
    }
    const spanStyle = {
      background: '#b3b3b3',
      display: 'inline-block',
      height: '15px',
      width: '1px',
      margin: '0 10px',
    }
    // Style for the container holding story cards
    const cardContainerStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'center',
    }

    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [200, 500],
            },
          ]}
        />

        <Container>
          <CardBody>
            <Form style={{ padding: '10px' }}>
              <Row>
                <h2
                  className="mt-5 mainPageTitle"
                  style={{
                    textAlign: 'center',
                    font: 'normal normal 400 2.2rem/1.4 Martel',
                    color: '#000',
                  }}
                >
                  My Stories
                </h2>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      borderBottom: '2px solid #FEC1EB',
                      height: '1px',
                      width: '102px',
                      display: 'inline-block',
                    }}
                  ></span>
                </div>
              </Row>
              <Row>
                <div
                  className="myStoryDesc"
                  style={{
                    font: 'normal normal 300 1.1rem/1.5 Fira Sans',
                    color: '#000',
                    textAlign: 'justify',
                  }}
                >
                  Welcome! Below are the stories YOU are writing. Personalise
                  your book cover in{' '}
                  <span style={{ fontStyle: 'italic' }}>story settings</span>,
                  add or view questions, order more copies of your book, or just
                  start writing!
                </div>
              </Row>
              {myStorieData &&
                myStorieData.map((order, index) => {
                  const currentDate = new Date() // Get current date
                  const expiryDate = new Date(order.expiry_date) // Convert expiry_date to Date object
                  // Calculate the difference between current date and expiry date
                  const timeDiff = expiryDate - currentDate
                  const daysUntilExpiry = timeDiff / (1000 * 60 * 60 * 24) // Convert milliseconds to days
                  const isExpired = expiryDate <= currentDate // Check if the expiry date has passed
                  const isExpiringSoon =
                    daysUntilExpiry > 0 && daysUntilExpiry <= 30 // Check if expiring within 30 days

                  return (
                    <Row style={cardContainerStyle} key={index}>
                      <div className="mt-5 story-settings story-setting-container">
                        <div className="my-book-details">
                          <div
                            className="details-book small-thumbnail"
                            style={{
                              height: '240px',
                              width: '200px',
                              boxShadow: '-4px 5px 12px 0px #bababa',
                              borderRadius: '2px',
                            }}
                          >
                            <div style={{ height: '-webkit-fill-available' }}>
                              {order.cover_page ? (
                                <img
                                  src={`${apiUrl}/images/${order.cover_page
                                    .split('/')
                                    .pop()}`}
                                  alt="Cover Page"
                                  style={{
                                    width: '200px',
                                    height: '240px',
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : (
                                <span
                                  style={{
                                    textTransform: 'uppercase',
                                    letterSpacing: '.05em',
                                    lineHeight: '1.2',
                                    display: 'block',
                                    marginBottom: '10px',
                                    backgroundColor: '#bababa',
                                    height: '100%',
                                    padding: '2em',
                                    fontSize: '0.85em',
                                    textAlign: 'center',
                                  }}
                                >
                                  {order.story_title || ''}
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            className="mt-3 my-book-title"
                            style={{ paddingLeft: '5%', width: '60%' }}
                          >
                            <div
                              style={{
                                font: 'normal normal 300 1.2rem/1.4 Martel',
                                textTransform: 'capitalize',
                                color: '#000',
                              }}
                            >
                              {order.story_title || ''}
                            </div>

                            <div
                              className="my-book-actions"
                              style={{
                                color: '#8A1A75',
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: '12px',
                                padding: '1%',
                                fontWeight: '500',
                                fontFamily: 'Fira Sans',
                              }}
                            >
                              {!isExpired && !isExpiringSoon && (
                                <>
                                  <Link
                                    to=""
                                    data-test-id={order.story_id}
                                    onClick={this.handleClick}
                                    className="link-style"
                                    style={{
                                      padding: '4px 0',
                                      justifyContent: 'left',
                                      minHeight: '1px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    {'STORY SETTINGS >'}
                                  </Link>
                                  <Link
                                    to=""
                                    data-test-id={order.story_id}
                                    onClick={this.handleAddQuestionClick}
                                    className="link-style"
                                    style={{
                                      padding: '4px 0',
                                      justifyContent: 'left',
                                      minHeight: '1px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    {'ADD QUESTIONS >'}
                                  </Link>
                                  <Link
                                    to=""
                                    data-test-id={order.story_id}
                                    onClick={this.handleViewQuestionClick}
                                    className="link-style"
                                    style={{
                                      padding: '4px 0',
                                      justifyContent: 'left',
                                      minHeight: '1px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    {'VIEW QUESTIONS >'}
                                  </Link>
                                  <Link
                                    data-test-id={order.story_id}
                                    onClick={this.handlePrintClick}
                                    className="link-style"
                                    style={{
                                      padding: '4px 0',
                                      justifyContent: 'left',
                                      minHeight: '1px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    {'PRINT MY BOOKS >'}
                                  </Link>
                                </>
                              )}
                            </div>

                            {isExpired && (
                              <div
                                className="my-book-actions"
                                style={{
                                  marginTop: '10px',
                                  padding: '1%',
                                  backgroundColor: '#f8d7da',
                                  color: '#721c24',
                                  border: '1px solid #f5c6cb',
                                  borderRadius: '4px',
                                }}
                              >
                                <div
                                  style={{
                                    font: 'normal normal 300 1.1rem/1.4 Fira Sans',
                                    color: '#404040',
                                  }}
                                >
                                  {'This book is expired. Please '}
                                </div>
                                <Link
                                  to=""
                                  data-test-id={order.story_id}
                                  onClick={this.handleRenewClick}
                                  className="link-style"
                                  style={{
                                    padding: '4px 0',
                                    justifyContent: 'left',
                                    minHeight: '1px',
                                    marginBottom: '0px',
                                    color: '#721c24',
                                  }}
                                >
                                  {'renew your subscription here'}
                                </Link>
                              </div>
                            )}

                            {isExpiringSoon && !isExpired && (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                  }}
                                >
                                  <Link
                                    to=""
                                    data-test-id={order.story_id}
                                    onClick={this.handleClick}
                                    className="link-style"
                                    style={{
                                      padding: '4px 0',
                                      justifyContent: 'left',
                                      minHeight: '1px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    {'STORY SETTINGS >'}
                                  </Link>
                                  <Link
                                    to=""
                                    data-test-id={order.story_id}
                                    onClick={this.handleAddQuestionClick}
                                    className="link-style"
                                    style={{
                                      padding: '4px 0',
                                      justifyContent: 'left',
                                      minHeight: '1px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    {'ADD QUESTIONS >'}
                                  </Link>
                                  <Link
                                    to=""
                                    data-test-id={order.story_id}
                                    onClick={this.handleViewQuestionClick}
                                    className="link-style"
                                    style={{
                                      padding: '4px 0',
                                      justifyContent: 'left',
                                      minHeight: '1px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    {'VIEW QUESTIONS >'}
                                  </Link>
                                  <Link
                                    className="link-style"
                                    data-test-id={order.story_id}
                                    onClick={this.handlePrintClick}
                                    style={{
                                      padding: '4px 0',
                                      justifyContent: 'left',
                                      minHeight: '1px',
                                      marginBottom: '0px',
                                    }}
                                  >
                                    {'PRINT MY BOOKS >'}
                                  </Link>
                                  <div
                                    className="my-book-actions"
                                    style={{
                                      marginTop: '10px',
                                      padding: '1%',
                                      backgroundColor: '#f8d7da',
                                      color: '#721c24',
                                      border: '1px solid #f5c6cb',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        font: 'normal normal 300 1.1rem/1.4 Fira Sans',
                                        color: '#404040',
                                      }}
                                    >
                                      {'This book is expiring soon. Please '}
                                    </div>
                                    <Link
                                      to=""
                                      data-test-id={order.story_id}
                                      onClick={this.handleRenewClick}
                                      className="link-style"
                                      style={{
                                        padding: '4px 0',
                                        justifyContent: 'left',
                                        minHeight: '1px',
                                        marginBottom: '0px',
                                        color: '#721c24',
                                      }}
                                    >
                                      {'renew your subscription here'}
                                    </Link>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Row>
                  )
                })}

              <Row className="mt-5 mb-4">
                <div
                  className="myStoriesLine"
                  style={{
                    borderBottom: '1px solid #ccc',
                    height: '2px',
                    width: '100%',
                  }}
                ></div>
              </Row>
              <Row className="mt-1" style={{ marginBottom: '7%' }}>
                <ul style={ulStyle}>
                  <li style={liStyle}>
                    <Link to="https://gys.coalescets.com/">BUY A STORY</Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li style={liStyle}>
                    <Link to="https://gys.coalescets.com/">GIFT A STORY</Link>
                  </li>
                </ul>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUserId: state.auth.session.user.id,
  }
}

const mapDispatchToProps = {
  listMyStoryDetails: myStoryOperations.listMyStoryDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyStories)
