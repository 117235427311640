import React, { Fragment, useContext } from 'react'
import { Container, CardBody, Col, Form, Row } from 'reactstrap'
import GoogleFontLoader from 'react-google-font-loader'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import Sidebar from '../../Layout/Sidebar'
import CustomizerContext from '../../_helper/Customizer/index.jsx'

const Contributors = () => {
  // Get userId from URL parameters
  const { layout } = useContext(CustomizerContext)
  const { sidebarIconType } = useContext(CustomizerContext)
  const layout1 = localStorage.getItem('sidebar_layout') || layout
  const sideBarIcon =
    localStorage.getItem('sidebar_icon_type') || sidebarIconType

  return (
    <Fragment>
      <GoogleFontLoader
        fonts={[
          { font: 'Martel', weights: [300, 700] },
          { font: 'Fira Sans', weights: [300, 400, 700] },
        ]}
      />
      <div
        className={`page-wrapper ${layout1}`}
        sidebar-layout={sideBarIcon}
        id="pageWrapper"
      >
        <Header isLoggedinHeader={true} />
        <div className="page-body-wrapper">
          <Sidebar isLoggedinHeader={true} />
          <Container fluid={true}>
            <CardBody>
              <Form style={{ maxWidth: '930px', margin: '0 auto' }}>
                <Row>
                  <h2
                    className="mt-5"
                    style={{
                      textAlign: 'center',
                      font: 'normal normal 300 2.5rem/1.4 Martel',
                      color: '#000',
                    }}
                  >
                    Contributor no longer has access
                  </h2>
                  <div style={{ textAlign: 'center' }}>
                    <span
                      style={{
                        borderBottom: '1px solid #FEC1EB',
                        height: '1px',
                        width: '102px',
                        display: 'inline-block',
                      }}
                    ></span>
                  </div>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col md="12">
                    <div style={{ textAlign: 'left' }}></div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Container>
          <Footer />
        </div>
      </div>
    </Fragment>
  )
}

export default Contributors
