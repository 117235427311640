import React, { Fragment } from 'react'
import {
  Container,
  Card,
  CardBody,
  Input,
  Col,
  Form,
  Row,
  Label,
} from 'reactstrap'
import { myStoryOperations } from '../../state/ducks/story/index.js'
import list from '../../assets/images/datatable/list.png'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import request from '../../utils/request.js'
import * as API_URL from '../../env.js'
import '../Button.css'
import GoogleFontLoader from 'react-google-font-loader'
import AddAnotherQuestios from './AddAnotherQuestion'

class SendQuestions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: '',
      showDetails: false,
      selectedCategory: '',
      showSecondDropdown: false,
      selectedQuestionText: '',
      selectedQuestionDescription: '',
      category: [],
      question: [],
      language: [],
      selectedQuestion: '',
      selectedGiftUserOrderData: [],
      numCols: 1,
      onAdd: false,
      GiftUserOrderData: {},
    }
  }

  async componentDidMount() {
    try {
      this.GiftUserData()
    } catch (err) {
      console.log(err)
    }
  }

  async GiftUserData() {
    try {
      const giftUserData = JSON.parse(
        localStorage.getItem('selectedGiftUserData')
      )
      this.setState({
        GiftUserOrderData: giftUserData,
      })
    } catch (err) {
      console.log(err)
    }
  }

  onInsert = async (event) => {
    event.preventDefault()
    try {
      const { GiftUserOrderData } = this.state
      // Extract the email and name from the first valid gift user
      const { user_email, user_name, orderId } = GiftUserOrderData
      const id = orderId
      const payload = {
        email: user_email, // Email of the gift user
        name: user_name,
        question: this.state.question,
        orderId: id,
      }
      const response = await this.props.sendQuestion(payload)
      toast.success(response.message, { autoClose: 10000 })
      window.open('/thank-you-for-your-question/', '_self')
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 })
      console.log(err)
    }
  }

  handleAddCol = () => {
    this.setState((prevState) => ({
      onAdd: true,
      numCols: prevState.numCols + 1,
    }))
  }

  handleRemoveCol = () => {
    alert('Are you sure you want to remove this group?')
    if (this.state.numCols > 0) {
      this.setState((prevState) => ({
        numCols: prevState.numCols - 1,
      }))
    }
  }

  render() {
    const { numCols, GiftUserOrderData } = this.state
    ;<AddAnotherQuestios
      handleRemoveCol={() => this.handleRemoveCol()}
      onAdd={this.state.onAdd}
    />
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <Card>
            <h1
              className="mt-5"
              style={{
                textAlign: 'center',
                font: 'normal normal 300 2.5rem/1.4 Martel',
                color: '#000',
              }}
            >
              Send Questions
            </h1>
            <span
              style={{
                borderBottom: '2px solid #FEC1EB',
                height: '2px',
                width: '102px',
                margin: '20px auto',
              }}
            ></span>
            <CardBody>
              <Form
                onSubmit={this.onInsert}
                style={{ maxWidth: '840px', margin: '0 auto' }}
              >
                <Row>
                  <Col md="12">
                    <div
                      style={{ font: 'normal normal 300 1.1rem/1.5 Fira Sans' }}
                    >
                      Selecting relevant questions is a great way to keep your
                      story teller{' '}
                      <span style={{ fontWeight: '500' }}>motivated</span> and{' '}
                      <span style={{ fontWeight: '500' }}>inspired</span>.
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div
                      style={{ font: 'normal normal 300 1.1rem/1.5 Fira Sans' }}
                    >
                      Remember to ask them about things you've always wanted
                      them to write down - and make sure their stories{' '}
                      <span style={{ fontWeight: '500' }}>
                        stay in the family for generations to come
                      </span>
                      .
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">
                    <div
                      style={{
                        font: 'normal normal 300 1.1rem/1.5 Fira Sans',
                        border: '1px solid #E2E2E2',
                        padding: '7px',
                        background: '#f8f9fa',
                      }}
                    >
                      Your created questions will be sent to{' '}
                      <span style={{ fontWeight: '500' }}>
                        {GiftUserOrderData.user_name}
                      </span>
                    </div>
                  </Col>
                </Row>
                {Array.from({ length: numCols }, (_, index) => (
                  <AddAnotherQuestios
                    handleRemoveCol={() => this.handleRemoveCol()}
                    onAdd={this.state.onAdd}
                    setQuestion={(question) => {
                      this.setState({
                        question: [...this.state.question, question],
                      })
                    }}
                  />
                ))}
                <Row className="mt-3">
                  <Col md="12">
                    <button
                      type="button"
                      onClick={this.handleAddCol}
                      style={{
                        background: '#fff',
                        color: '#8A1A75',
                        fontSize: '.9rem',
                        letterSpacing: '.1rem',
                        border: '1px solid #8A1A75',
                        fontWeight: '300',
                        borderRadius: '30px',
                        padding: '0.5rem 1rem',
                        textTransform: 'uppercase',
                        minHeight: '45px',
                      }}
                    >
                      ADD ANOTHER Question
                    </button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} style={{ textAlign: 'center' }}>
                    <button
                      type="submit"
                      style={{
                        background: '#8A1A75',
                        letterSpacing: '0.15rem',
                        border: '2px solid #8A1A75',
                        fontWeight: '300',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '0 20px',
                        textTransform: 'uppercase',
                        minHeight: '45px',
                      }}
                    >
                      Send these Question
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  sendQuestion: myStoryOperations.sendQuestion,
}

export default connect(mapStateToProps, mapDispatchToProps)(SendQuestions)
