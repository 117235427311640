import React, { Fragment } from 'react'
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Button,
  Label,
} from 'reactstrap'
import GoogleFontLoader from 'react-google-font-loader'
import '../Button.css'
import { toast } from 'react-toastify'
import { myStoryOperations } from '../../state/ducks/story'
import { connect } from 'react-redux'

class RenewBookSubscription extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subscriptionId: 0,
      discountId: 0,
      storyId: 0,
      discountsData: [],
      pricingOption: [], // Stores the pricing options fetched from API
      selectedDuration: '', // Stores the selected duration
      discountCode: '', // Stores the entered discount code
      discountAmount: 0, // Stores the discount amount
      subtotal: 0, // Stores the subtotal amount
      total: 0, // Stores the total amount after discount
      errors: '',
    }
  }

  async componentDidMount() {
    const { loggedInUserId } = this.props
    if (!loggedInUserId) {
      console.log('No user ID Found')
      return
    }
    const storyId = localStorage.getItem('selectedStoryId')
    const response = await this.props.getSubscriptionPricing({
      user_id: loggedInUserId,
      story_id: storyId,
    })
    if (response.payload && Array.isArray(response.payload)) {
      const pricingOptions = response.payload.map((option) => ({
        ...option,
        amount: parseFloat(option.amount), // Ensure amount is a number
      }))
      // Pre-select the first option (if available)
      const firstOption = pricingOptions[0]
      this.setState({
        pricingOption: pricingOptions,
        subscriptionId: firstOption ? firstOption.id : 0,
        storyId: firstOption ? firstOption.story_id : 0,
        selectedDuration: firstOption ? firstOption.duration : '',
        subtotal: firstOption ? firstOption.amount : 0,
        total: firstOption ? firstOption.amount : 0,
      })
      this.setState({ pricingOptions: pricingOptions })
    } else {
      console.log('No subscription princing avaliable')
    }
    this.setState({
      pricingOption: response.payload,
    })
  }

  handleDurationChange = (event) => {
    const selectedDuration = parseInt(event.target.value, 10)
    const selectedPrice = this.state.pricingOption.find(
      (option) => option.duration_year === selectedDuration
    )
    if (selectedPrice) {
      this.setState({
        selectedDuration,
        subtotal: parseFloat(selectedPrice.amount), // Ensure amount is a number
        total: parseFloat(selectedPrice.amount) - this.state.discountAmount,
      })
    } else {
      this.setState({ selectedDuration, subtotal: 0, total: 0 })
    }
  }

  handleDiscountChange = (event) => {
    this.setState({ discountCode: event.target.value })
  }

  applyDiscount = async () => {
    const { discountCode, subtotal } = this.state
    if (!discountCode) {
      toast.error('Please enter a discount code.')
      return
    }
    const response = await this.props.getDiscountCode({ code: discountCode })
    if (
      response.status === 200 &&
      response.payload &&
      response.payload.value !== 0
    ) {
      const discountId = response.payload.id
      const discountVal = parseFloat(response.payload.value || 0)
      const total = subtotal - discountVal

      this.setState({
        discountId: discountId,
        discountAmount: discountVal,
        total: total > 0 ? total : 0,
        errors: '',
      })
      toast.success('Discount applied successfully!')
    } else {
      this.setState({
        errors: 'The discount code you entered is not valid.',
        discountAmount: 0,
        total: subtotal,
      })
    }
  }

  handleGoToPayment = async (event) => {
    event.preventDefault()
    const { loggedInUserId } = this.props
    const {
      storyId,
      selectedDuration,
      subtotal,
      total,
      discountAmount,
      discountId,
    } = this.state

    if (!selectedDuration) {
      toast.error('Please select a subscription duration.')
      return
    }

    try {
      const response = await this.props.RenewSubscription({
        story_id: storyId,
        user_id: loggedInUserId,
        duration_year: selectedDuration,
        currency: 'GBP', // Hardcoded or fetched from state/props
        discount_id: discountId,
        subtotal_pricing: subtotal,
        discount_pricing: discountAmount,
        total_pricing: total,
      })

      if (response) {
        // Redirect to payment URL
        window.location.href = response.payload.payment_url
      } else {
        toast.error('Failed to renew subscription. Please try again.')
      }
    } catch (error) {
      console.error('Error during subscription renewal:', error)
      toast.error('An error occurred while processing your renewal.')
    }
  }

  render() {
    const {
      pricingOption,
      selectedDuration,
      discountCode,
      subtotal,
      total,
      discountAmount,
    } = this.state

    // Calculate Subtotal
    const subtotalPrice = pricingOption.reduce((acc, option) => {
      return acc + parseFloat(option.story_amount || 0) // Safely add story_amount as number
    }, 0)

    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            { font: 'Martel', weights: [300, 700] },
            { font: 'Fira Sans', weights: [200, 500] },
          ]}
        />
        <Row>
          <h2
            className="mt-5 mainPageTitle"
            style={{
              textAlign: 'center',
              font: 'normal normal 300 2.5rem/1.4 Martel',
              color: '#000',
            }}
          >
            Renew book subscription
          </h2>
          <div style={{ textAlign: 'center' }}>
            <span
              style={{
                borderBottom: '2px solid #FEC1EB',
                height: '1px',
                width: '102px',
                display: 'inline-block',
              }}
            ></span>
          </div>
        </Row>

        <Container
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '100vh', padding: '20px' }}
        >
          <Card
            style={{
              width: '100%',
              maxWidth: '60rem',
              backgroundColor: '#f8f3f1',
              border: 'none',
              padding: '30px',
            }}
          >
            <CardBody>
              <Form>
                {/* Duration Selection */}
                <Row className="mb-4">
                  <Col xs={12} style={{ textAlign: 'center' }}>
                    <Label
                      for="durationSelect"
                      style={{
                        fontFamily: 'Martel',
                        fontSize: '16px',
                        fontWeight: '500',
                      }}
                    >
                      How long would you like to renew this book for?
                    </Label>
                    <Input
                      type="select"
                      id="durationSelect"
                      value={selectedDuration}
                      onChange={this.handleDurationChange}
                      style={{
                        width: '100%',
                        maxWidth: '650px',
                        padding: '8px',
                        margin: '10px auto 0',
                        backgroundColor: '#fff',
                        border: '1px solid #d4cfcf',
                        borderRadius: '50px',
                        font: 'normal normal normal 15px/17px Fira Sans',
                        fontWeight: '400',
                        color: '#43107E',
                      }}
                    >
                      {pricingOption.map((option, index) => (
                        <option key={index} value={option.duration_year}>
                          {option.duration_year === 1
                            ? '1 Year'
                            : option.duration_year === 2
                            ? '2 Years'
                            : option.duration_year === 3
                            ? '3 Years'
                            : `${option.duration_year} Year(s)`}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                {/* Discount Code */}
                <Row className="mb-4">
                  <Col xs={12} style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        padding: '15px',
                        border: '1px solid #d4cfcf',
                        width: '100%',
                        maxWidth: '650px',
                        margin: 'auto',
                        flexWrap: 'wrap', // Allow wrapping for smaller screens
                      }}
                    >
                      <span
                        style={{
                          font: 'normal normal 300 1.1rem/1.4 Fira Sans',
                          color: '#404040',
                          textTransform: 'uppercase',
                        }}
                      >
                        Discount Code
                      </span>
                      <Input
                        type="text"
                        value={discountCode}
                        onChange={this.handleDiscountChange}
                        style={{
                          flex: '1',
                          borderRadius: '30px',
                          border: '1px solid #d4cfcf',
                          padding: '10px 15px',
                          minWidth: '150px',
                        }}
                      >
                        <div className="invalid-feedback">
                          {this.state.errors}
                        </div>
                      </Input>
                      <button
                        type="button"
                        className="white-button"
                        style={{ minWidth: '100px' }}
                        onClick={this.applyDiscount}
                      >
                        APPLY
                      </button>
                    </div>
                    {this.state.errors && (
                      <p
                        style={{
                          color: 'red',
                          fontSize: '14px',
                          marginTop: '5px',
                          textAlign: 'left',
                          marginLeft: '97px',
                        }}
                      >
                        {this.state.errors}
                      </p>
                    )}
                  </Col>
                </Row>

                {/* Subtotal & Total */}
                <Row className="mb-4">
                  <Col xs={12} style={{ textAlign: 'center' }}>
                    <div
                      style={{
                        width: '100%',
                        maxWidth: '650px',
                        padding: '15px',
                        margin: 'auto',
                        backgroundColor: '#fff',
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontFamily: 'Fira Sans',
                          fontSize: '14px',
                          color: '#404040',
                          fontWeight: '300',
                        }}
                      >
                        Subtotal
                        <span style={{ color: '#8A1A75' }}>
                          £
                          {typeof subtotal === 'number'
                            ? subtotal.toFixed(2)
                            : '0.00'}
                        </span>
                      </p>
                      <p
                        style={{
                          margin: 0,
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontFamily: 'Fira Sans',
                          fontSize: '14px',
                          color: '#404040',
                          fontWeight: '300',
                        }}
                      >
                        Discount
                        <span style={{ color: '#8A1A75' }}>
                          -£
                          {typeof discountAmount === 'number'
                            ? discountAmount.toFixed(2)
                            : '0.00'}
                        </span>
                      </p>
                      <p
                        style={{
                          margin: 0,
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontFamily: 'Fira Sans',
                          fontSize: '14px',
                          color: '#404040',
                          fontWeight: '300',
                        }}
                      >
                        Total
                        <span style={{ color: '#8A1A75' }}>
                          £
                          {typeof total === 'number'
                            ? total.toFixed(2)
                            : '0.00'}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>

                {/* Go to Payment Button */}
                <Row>
                  <Col xs={12} style={{ textAlign: 'center' }}>
                    <button
                      type="submit"
                      style={{
                        background: '#8A1A75',
                        letterSpacing: '0.15rem',
                        border: '2px solid #8A1A75',
                        fontWeight: '300',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '0 20px',
                        textTransform: 'uppercase',
                        minHeight: '45px',
                        width: '100%',
                        maxWidth: '300px', // Center-align for smaller screens
                      }}
                      onClick={this.handleGoToPayment}
                    >
                      Go to Payment
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUserId: state.auth.session.user.id,
  }
}

const mapDispatchToProps = {
  RenewSubscription: myStoryOperations.RenewSubscription,
  getSubscriptionPricing: myStoryOperations.getSubscriptionPricing,
  getDiscountCode: myStoryOperations.getDiscountCode,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenewBookSubscription)
